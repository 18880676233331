@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700;800&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    text-align: center;
}

.center-button {
    margin: auto auto auto auto;
}

#center-button {
    display: block;
    margin: 0 auto;
}


h2 {
    font-style: italic;
}

* {
    font-family: 'Readex Pro', sans-serif;
}

.primary-title {
    font-size: 650%;
    color: auto;
    vertical-align: top;
    margin-top: 120px;
    margin-bottom: 0px;
    font-weight: lighter;

}

.primary-button {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    margin: 10px;
}

.primary-button-logout {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    margin-right: 50px;
}

.primary-button-logout:hover {
    background: linear-gradient(215deg, rgb(254, 48, 114), rgb(255, 89, 64));
}

.primary-button-edit-profile {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(250, 154, 28), rgb(21, 111, 247));

    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    margin-right: 50px;
    text-decoration: none;
}

.primary-button-edit-profile:hover {
    background: linear-gradient(140deg, rgb(250, 154, 28), rgb(21, 111, 247));
}

.primary-button-ver-vaga {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(73, 45, 45), rgb(243, 123, 25));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    margin-right: 50px;
    /* width: 210px; */
}


.primary-button-login-cand {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    /* margin-top: 20px; */
    margin-left: 75px;
    /* margin-bottom: 10px; */
}

.primary-button2 {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(86, 48, 254), rgb(255, 64, 191));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    margin: 10px;
}

.primary-button-login-emp {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(86, 48, 254), rgb(255, 64, 191));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    /* margin-top: 20px; */
    margin-right: 75px;
    /* margin-bottom: 10px; */
}


.hr-vagas {
    border-top: 1px solid #cccbcb;
}

.primary-button-criar-vaga {
    color: rgb(255, 255, 255);
    width: 85%;
    height: 30%;
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(255, 28, 28), rgb(243, 123, 25));
    padding: 12px 30px;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}


.primary-button:hover {
    background: linear-gradient(260deg, rgb(243, 123, 25), rgb(255, 28, 28));
}

.primary-button:hover {
    background: linear-gradient(260deg, rgb(254, 48, 114), rgb(255, 89, 64));
}

.primary-button-login-cand:hover {
    background: linear-gradient(260deg, rgb(254, 48, 114), rgb(255, 89, 64));
}

.primary-button2:hover {
    background: linear-gradient(260deg, rgb(255, 64, 191), rgb(86, 48, 254));
}

.primary-button-login-emp:hover {
    background: linear-gradient(260deg, rgb(255, 64, 191), rgb(86, 48, 254));
}


.secondary-button {
    color: rgb(121, 119, 119);
    background-color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    padding: 12px 30px;
    border-radius: 30px;
    border: solid 2px rgb(121, 119, 119);
    margin: 6px;
}

.secondary-button:hover {
    color: rgb(48, 48, 48);
    border: solid 2px rgb(48, 48, 48);
}


/*-------- Navbar --------*/

nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    /* height: 8%; */
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 0px 10px 0px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

nav .nav-button-emp {
    color: rgb(254, 48, 114);
    background-color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    margin-right: 75px;
    padding-top: 10px;
    text-align: center;
}

nav .nav-button-emp:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(254, 48, 114)
}

nav .nav-button-emp:disabled {
    background-color: rgb(181, 180, 180);
    color: rgb(189, 42, 90);
}

nav .nav-button-cand {
    color: rgb(254, 48, 114);
    background-color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    margin-left: 75px;
    padding-top: 10px;
    text-align: center;
}

nav .nav-button-cand:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(254, 48, 114)
}

nav .nav-button-cand:disabled {
    background-color: rgb(181, 180, 180);
    color: rgb(189, 42, 90);
}

nav .logo-container {
    width: 400px;
    margin-left: 40px;
    margin-right: auto;
}

nav .logo-container .logo {
    width: 200%;
}

/*-------- Home page --------*/
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: linear-gradient(to bottom,
            rgba(136, 45, 255, 0.2) 0%,
            rgba(136, 169, 179, 0.00) 100%)
}

.home {
    margin-top: 0px;
}

/*-------- Auth Modal EMPRESA --------*/
.auth-modal-emp {
    position: absolute;
    right: 75px;
    top: 125px;
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 40px;
    line-break: auto;
    border: solid 1px rgba(0, 0, 0, 0.2);
}

.h2-auth-modal {
    font-size: 25px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.auth-modal-emp .close-icon {
    float: right;
    font-size: 50px;
}

.auth-modal-emp form {
    display: flex;
    flex-direction: column;
}

.auth-modal-emp input[type=text],
.auth-modal-emp input[type=password],
.auth-modal-emp input[type=cnpj],
.auth-modal-emp input[type=email] {
    padding: 8px;
    margin: 8px;
    font-size: 17px;
}

/*-------- Auth Modal --------*/
.auth-modal-cand {
    position: absolute;
    left: 75px;
    top: 125px;
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 40px;
    border: solid 1px rgba(0, 0, 0, 0.2);
}

.auth-modal-cand .close-icon {
    float: right;
    font-size: 50px;
}

.auth-modal-cand form {
    display: flex;
    flex-direction: column;
}

.auth-modal-cand input[type=text],
.auth-modal-cand input[type=password],
.auth-modal-cand input[type=cnpj],
.auth-modal-cand input[type=email] {
    padding: 8px;
    margin: 8px;
    font-size: 17px;
}

/*-------- OnBoarding Page --------*/
.onboarding {
    padding: 3%;
    border-top: solid 1px rgb(213, 213, 213);
    height: 850px;
}

.onboarding form {
    display: flex;
    justify-content: center;
}

.onboarding form section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 57%;
    text-align: start;
    overflow-y: auto;
}

.onboarding form input {
    padding: 15px 30px;
    margin: 10px 0;
    font-size: 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 10px;
}

.onboarding form select {
    padding: 15px 30px;
    margin: 10px 0;
    font-size: 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 10px;
}

.onboarding form textarea {
    padding: 15px 30px;
    margin: 10px 0;
    font-size: 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    /* Inclui padding e border no cálculo da largura */
    resize: vertical;
    /* Permite redimensionar verticalmente, mas não horizontalmente */

}


.submit-forms {
    /* position: relative; */
    margin: 5px auto;
    /* Centraliza o botão horizontalmente */
    width: 30%;
    font-weight: bolder;
    padding: 8px 20px;
    font-size: 20px;
    border: solid 2px rgb(87, 87, 87);
    border-radius: 10px;
    display: block;
    /* Garante que o elemento seja tratado como um bloco, permitindo centralização */
}


.submit-forms:hover {
    border: solid 2px rgb(0, 0, 0);
}

#save-button {
    padding: 8px 20px;
    /* Ajusta o tamanho do botão */
    margin: 5px;
    /* Ajusta o espaçamento */
    /* Ajusta o tamanho da fonte */
    border: solid 2px rgb(87, 87, 87);
    border-radius: 10px;
}

.onboarding form button {
    padding: 15px 30px;
    margin: 10px 0;
    font-size: 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 10px;
}

.onboarding form label {
    margin: 10px 0;
}

.onboarding form .multiple-input-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
}

#center-p {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    margin-left: 10px;
}

.onboarding form input::-webkit-outer-spin-button,
.onboarding form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.onboarding form .multiple-input-container input {
    margin-right: 10px;
}

.onboarding form .multiple-input-container label {
    padding: 10px;
    border: solid 2px rgb(144, 144, 144);
    border-radius: 10px;
    transition: all 0.3s;
    margin-right: 10px;
}

.checkbox-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    /* Impede seleção acidental de texto */
}

.checkbox-container input {
    display: none;
    /* Esconde o checkbox padrão */
}

.custom-checkbox {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    /* Espaço entre o texto e o quadrado */
    border: 2px solid #555;
    border-radius: 4px;
    background-color: #fff;
    transition: all 0.2s ease;
    display: inline-block;
    position: relative;
}

.checkbox-container input:checked+.custom-checkbox {
    background-color: #4caf50;
    /* Cor de fundo ao marcar */
    border-color: #4caf50;
}

.checkbox-container input:checked+.custom-checkbox::after {
    content: "";
    width: 10px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.2s ease;
}

.onboarding form input[type=radio] {
    display: none;
}

.onboarding form input[type=image] {
    size: 200px;
}

.onboarding form input[type=number] {
    width: 10%;
}

.onboarding form input[type=radio]:checked+label {
    border: solid 2px rgb(221, 39, 22);
}

.onboarding form input[type=submit]:hover {
    background-color: rgb(235, 235, 235);
}

.onboarding form input[type=submit]:active {
    background-color: rgb(226, 115, 115);
}

.onboarding form .photo-container img {
    width: 100%
}

.dashboard {
    /* padding-top: 6%; */
    display: flex;
    height: 100%;
    width: 100%;
    gap: 20px;
    margin-top: 8vh;
    justify-content: space-between;
}

.dashboard .swipe-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


/*-------- Chat Container --------*/
.chat-container {
    position: fixed;
    width: 30%;
    height: 94%;
    text-align: left;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: rgb(255, 255, 255);
}


.chat-container .chat-container-header {
    background: linear-gradient(45deg, rgb(2, 82, 136), rgb(255, 89, 64));
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-container .option {
    border: none;
    background-color: rgb(255, 255, 255);
    border-bottom: solid 4px rgb(243, 33, 33);
    font-size: 20px;
    margin: 2px;
    padding: 10px;
}

.chat-container .option:disabled {
    border-bottom: solid 4px rgb(187, 187, 187);
}

.chat-container .profile,
.chat-container .log-out-icon {
    display: flex;
    align-items: center;
    padding: 20px;
    color: rgb(255, 255, 255);
}

.img-container {
    height: 40px;
    width: 40px;
    border-radius: 15px;
    overflow: hidden;
    margin: 10px;
}

.img-container img {
    width: 100%;
}

/*-------- Chat Display --------*/
.chat-display,
.matches-display {
    padding: 20px;
    height: 60vh;
    overflow-y: auto;
}

/*-------- Chat Input --------*/
.chat-input {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.br-space {
    margin-top: 10px;
    margin-bottom: 10px;
}

.div-number {
    font-size: 4px;
}

#uf_candidato {
    width: 10%;
}

#n_empresa {
    font-size: bold;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Ocupa 100% da largura para centralizar o conteúdo */
}

.home-icon {
    margin-top: 7px;
    font-size: 40px;
    /* margin-left: 25px; */
    cursor: pointer;
}

.search-bar {
    width: 600px;
    margin-left: 15px;
    /* Ajuste o valor conforme necessário */
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 4px;

}




.dashboard .swipe-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.swipe {
    position: absolute;
}

.dashboard .card-container {
    width: 400px;
    height: 100%;
    margin-right: 18px;
}

.h2-title-dashboard {
    margin-left: 30vh;
}

.card-container {
    flex: 1;
    height: 100%;
}


.card {
    height: auto;
    max-height: 600px;
    background-color: #f8dfdf;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0px;
    cursor: pointer;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card:hover {
    transform: translateY(-5px);
    background-color: #f7e3e3;
}

.card p {
    margin: 2% 4% 2% 4%;
    color: #666;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ul-card {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: auto;
    text-overflow: ellipsis;
    position: relative;
    list-style: square;
}

ul::-webkit-scrollbar {
  width: 8px;
}


.ul-card::-webkit-scrollbar-thumb {
    background-color: #815a5a; /* Cor do polegar (parte que o usuário arrasta) - preto */
    border-radius: 4px; /* Bordas arredondadas */
}


.ul-card::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Cor do polegar ao passar o mouse */
}


.ul-card::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Cor do fundo da barra de rolagem */
    border-radius: 4px; /* Bordas arredondadas */
}

.ul-card::-webkit-scrollbar-track-piece {
    background-color: #e0e0e0; /* Cor do fundo do trilho */
}



.card-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: auto;
}



.cardContent {
    width: 100%;
    height: 100%;
}


/* .card h3 {
    position: relative;
    margin-top: 20px;
    color: black;
    margin-right: 0;
    margin-left: 0;

    box-shadow: 2px 2px 60px 2px rgba(0, 0, 0, 0.30);
    background-color: rgb(219, 219, 219);
    border-radius: 40px;
    padding: 4px;
    font-size: 25px;
} */

.card h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
}

.card-formation {
    margin-top: 520px;
    box-shadow: 2px 2px 60px 2px rgba(0, 0, 0, 0.30);
    background-color: rgb(219, 219, 219);
    border-radius: 30px;
    padding: 1px;
    font-size: 20px;
    font-weight: bold;
}

.dashboard .swipe-info {
    position: absolute;
    bottom: 0;
    padding: 10px;
}




.modal img {
    border-radius: 15px;
    width: 80%;
    height: 80%;

}



.modal p {
    text-align: justify;
    margin-left: 15%;
    margin-right: 15%;
}

.close {
    font-size: 2.5em;
    cursor: pointer;
    float: right;
}

.scroll-content {
    height: 70%;
    overflow-y: auto;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Exemplo MODAIS */

.matches-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.match-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    cursor: pointer;
}

.match-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.match-card p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}

.buttons-swipe button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin-top: 45px;
    margin-left: 50px;
    margin-right: 50px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
    font-size: 25px;
    text-align: center;
}


.buttons-swipe button:hover {
    transform: scale(1.05);
}

.buttons-swipe .buttons-swipe-dislike {
    background: linear-gradient(45deg, rgb(255, 0, 34), rgb(252, 113, 92));
}

.buttons-swipe .buttons-swipe-like {
    background: linear-gradient(45deg, rgb(49, 219, 49), rgb(51, 138, 1));
}

.buttons-swipe .buttons-swipe-undo {
    background: linear-gradient(180deg, #9198e5, #4c5af7);
}

#save-button {
    border-color: #687400;
}

#save-button:hover {
    border-color: #4c5af7;
}

.form-container {
    display: block;
    width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    /* margin-top: 20px; */
    margin: 0px;
    width: 100%;
}

li {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    display: block;
}

#margin-button {
    margin-left: 10px;
}

.info-wrapper {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    font-style: normal;
}

.tooltip-text {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: -340%;
    right: 750%;
    margin-left: 50px;
    opacity: 0;
    transition: opacity 0.3s;
    font-style: normal;
}

.info-wrapper:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.matches-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: auto;
}

.match-card {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(33.33% - 10px);
    margin: 5px;
    cursor: pointer;
    margin-bottom: 30px;
}

.match-initial-header {
    width: 35px;
    height: 35px;
    background: linear-gradient(30deg, rgb(255, 153, 0) 70%, rgb(209, 253, 10) 25%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    border: solid 2px rgb(255, 255, 255);
}

.match-initial-header:hover {
    width: 35px;
    height: 35px;
    background: linear-gradient(30deg, rgb(255, 153, 0) 25%, rgb(209, 253, 10) 70%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    border: solid 2px rgb(255, 255, 255);
}

.match-initial {
    width: 50px;
    height: 50px;
    background: linear-gradient(30deg, rgb(2, 82, 136), rgb(255, 89, 64));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
    border: solid 2px black;
    z-index: 1;

}

.match-card:hover .match-initial {
    background: linear-gradient(210deg, rgb(2, 82, 136), rgb(255, 89, 64));
}

.match-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 0;
}

.match-card:hover::after {
    opacity: 1;

}

.match-name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
}


/* CSS LISTA */

.list-container {
    padding: 20px;
    width: 70%;
    margin-right: 20px;
    overflow-y: auto;
}

.candidate-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.candidate-item {
    width: 100%;

    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;

    justify-content: space-between;

}

.candidate-info {
    display: flex;
    align-items: center;
}

.candidate-info h3 {
    margin-right: 15px;
}

.candidate-info p {
    margin-right: 15px;
}

.accept-button,
.reject-button {
    flex: 1;
    padding: 8px 8px;
    border-radius: 5px;
    font-size: 0.9em;
    border: none;
    color: white;
    cursor: pointer;
    margin: 5px;
}

#hover-color-button:hover {
    background-color: #cedf72;
}

#button-container {
    /* display: flex; */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    width: 400px;
}

.accept-button {
    background-color: #28a745;
    color: white;
}

.accept-button:hover {
    background-color: #5dcf78;
    color: black;
}

.reject-button {
    background-color: #f44336;
}

.reject-button:hover {
    background-color: #eb7480;
    color: rgb(0, 0, 0);
}

.modal {
    position: fixed;
    z-index: 1;
    height: 100%;
    top: 6.8%;
    left: 30%;
    width: 70%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  } */

/* .modal.show {
    opacity: 1;
    transform: translateY(0);
} */

.modal.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    margin: 15px 15px 15px 15px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: none;
    height: 80%;
    max-height: 90vh;
    box-shadow: 2px 2px 60px 2px rgba(0, 0, 0, 0.30);
    border: solid 1px gray;
}

/* .modal-content {
    margin: 5% auto;
    background-color: #fefefe;
    padding: 20px;
    border-radius: 30px;
    width: 80%;
    max-width: 600px;
    border: 1px solid #888;
    box-shadow: 2px 2px 60px 2px rgba(0, 0, 0, 0.30);
    border: solid 1px gray;
} */

/* .modal-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
  } */

/* .modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
} */



.modal-button {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}


.modal-button:hover {
    padding: 11px 30px;
    box-shadow: 0 8px 16px rgba(153, 151, 151, 0.3);
    background: linear-gradient(45deg, rgb(248, 15, 89) 2%, rgb(255, 34, 0) 80%);

}

.dropdown {
    position: relative;
    /* Necessário para posicionar o dropdown */
}

.open-positions-dropdown {
    width: 500px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    list-style: none;
    padding: 10px 0;
    z-index: 1000;

}

.open-positions-dropdown li {
    padding: 10px 15px;
    cursor: pointer;
}

.open-positions-dropdown li:hover {
    background-color: #f0f0f0;
}

.scroll-list-vagas {
    overflow-y: auto;
    height: 215px;
}















.open-positions-dropdown li.selected {
    background-color: #f0f0f0;
}

.open-positions-dropdown li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.position-info {
    flex: 1;
}

.button-group {
    display: flex;
    gap: 10px;
}

.edit-button {
    margin-left: 5px;
    background: linear-gradient(135deg, #4A90E2, #357ABD);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-button:hover {
    background: linear-gradient(135deg, #357ABD, #2B6CA3);
}

/* Botão de excluir */
.delete-button {
    background: linear-gradient(135deg, #f44336, #e57373);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background: linear-gradient(135deg, #d32f2f, #ef5350);
}




.cards {
    padding-left: 31%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    margin-top: 20px;
}












.selected-job {
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px;
    margin-top: 20px;
    margin-right: 75px;
    margin-bottom: 10px;
}



.disabled-button {
    opacity: 0.5;
    pointer-events: none;
}








.chat-display {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    max-height: 70vh;
    overflow-y: auto;
}

.chat-message-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 70%;
}

.chat-message-container.sent {
    align-self: flex-end;
    text-align: right;
}

.chat-message-container.received {
    align-self: flex-start;
    text-align: left;
}

.chat-message-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.img-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat-message-body {
    padding: 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.chat-message-container.sent .chat-message-body {
    background-color: #d1f7d6;
}

.timestamp {
    font-size: 0.8rem;
    color: #888;
    margin-top: 5px;
}